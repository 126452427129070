import { AllExploreSlugList } from './explore.constant';

const { default: CompetitiveEdgeIcon } = require('app/assets/icons/internal_pages/CompetitiveEdgeIcon');
const { default: CoreFeaturesIcon } = require('app/assets/icons/internal_pages/CoreFeaturesIcon');
const { default: HowItWorksIcon } = require('app/assets/icons/internal_pages/HowItWorksIcon');
const { default: LaptopIcon } = require('app/assets/icons/internal_pages/LaptopIcon');
const { default: WhoItsForIcon } = require('app/assets/icons/internal_pages/WhoItsForIcon');

export const AllFeaturesConstArray = [
  {
    title: 'Who it’s For',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: WhoItsForIcon,
    link: '/features/why-its-for'
  },
  {
    title: 'Core Features',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: CoreFeaturesIcon,
    link: '/features/core-features'
  },
  {
    title: 'Competitive Edge',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: CompetitiveEdgeIcon,
    link: '/features/competitive-edge'
  },
  {
    title: 'How it Works',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: HowItWorksIcon,
    link: '/features/how-it-works'
  },
  {
    title: 'Digital Transformation Impact',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/features/digital-transformation-impact'
  }
];

export const WhyItsForFeatures = [
  {
    title: 'Banking & Financial Institutions',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    link: '/features/why-its-for/banking-financial-institutions',
    Icon: LaptopIcon
  },
  {
    title: 'Government & Public Sector',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    link: '/features/why-its-for/govt-public-sector',
    Icon: LaptopIcon
  },
  {
    title: 'Legal Professionals',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    link: '/features/why-its-for/legal-professionals',
    Icon: LaptopIcon
  },
  {
    title: 'Corporate Enterprises',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    link: '/features/why-its-for/corporate-enterprises',
    Icon: LaptopIcon
  }
];

export const AllSlugsWithTitle = {
  '': 'Home',
  features: 'Features',
  'why-its-for': 'Why It’s For',
  templates: 'Template Library',
  blogs: 'Blogs',
  'terms-of-use': 'Terms Of Use',
  'privacy-policy': 'Privacy Policy',
  faqs: 'FAQs',
  integrations: 'Integrations',
  contracts: 'Contracts',
  workflows: 'Workflows',
  'human-resources': 'Human Resources',
  'gov-regulatory-&-compliance': 'Government Regulatory & Compliance',
  'banking-insurance-&-real-estate': 'Banking Insurance & Real Estate',
  'document-delivery-&-tracking': 'Document Delivery & Tracking',
  integration: 'Integrations',
  // 'contact-us': 'Contact Us',
  // 'about-us': 'About Us',
  'case-studies': 'Case Studies',
  whitepapers: 'Whitepapers',
  pricing: 'Pricing'
};

export const getAllWithTitle = (routes, existingSlugsWithTitle = AllSlugsWithTitle) => {
  const result = {};

  Object.keys(routes).forEach((route) => {
    // Get the last slug from the route by splitting by '/' and taking the last part
    const lastSlug = route.split('/').pop();

    // If the last slug already exists in the `AllSlugsWithTitle` mapping, use that
    if (existingSlugsWithTitle[lastSlug]) {
      result[lastSlug] = existingSlugsWithTitle[lastSlug];
    } else {
      // Otherwise, use the title from the route object
      result[lastSlug] = routes[route].title;
    }
  });

  return result;
};
const BankingNFinancialInstitutionsList = [
  {
    title: 'Paperless Customer Onboarding',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  { title: 'Loan Documentation', text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.', Icon: LaptopIcon },
  {
    title: 'Investments Agreements',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  { title: 'KYC Verification', text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.', Icon: LaptopIcon },
  { title: 'Account Opening Forms', text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.', Icon: LaptopIcon }
];

const LegalProfessionalsList = [
  {
    title: 'Court Documents'
  },
  { title: 'Legal Contracts' },
  {
    title: 'Affidavits'
  },
  { title: 'Agreements' },
  { title: 'Notices' }
];

const GovermentAndPublicSectorList = [
  {
    title: 'Citizen Services'
  },
  { title: 'Inter Departmental Approvals' },
  {
    title: 'Public Private Partnerships'
  },
  { title: 'Regulatory Filings' },
  { title: 'Compliance Form Filing' }
];
const CorporateEnterprisesList = [
  {
    title: 'Employee Documentation'
  },
  { title: 'Vendor Contracts' },
  {
    title: 'Board Resolutions'
  },
  { title: 'Compliance Documents' },
  { title: 'Internal Policies' }
];
const CoreFeaturesList = [
  {
    title: 'Aadhar Based Authentication',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/features/core-features/aadhar-based-authentication'
  },
  {
    title: 'Legal Compliance Hub',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/features/core-features/legal-compliance-hub'
  },
  {
    title: 'Smart Workflow Tools',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/features/core-features/smart-workflow-tools'
  },
  {
    title: 'Microsoft Intergration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/features/core-features/microsoft-integration'
  },
  {
    title: 'Template Management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/features/core-features/template-management'
  },
  {
    title: 'Enterprise Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/features/core-features/enterprise-integration'
  }
];

const AadharBasedAuthFeaturesList = [
  {
    title: 'Direct Integration with UIDAI',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Real Time verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Biometric Compatibility',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Multi-Factor Authentication',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Tamper-evident seals',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const LegalComplianceHubList = [
  {
    title: 'IT Act 2000 compliant',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Court-admissible audit trails',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Time stamping certification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Digital Signature certificates',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'eSign API Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'GDPR Ready',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Data Residency in India',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const SmartWorkflowToolsList = [
  {
    title: 'Custom approval chains',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automated reminders',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Role-based access control ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document tracking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bulk signing capability',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const MicrosoftIntegrationList = [
  {
    title: 'Word add-in',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Outlook plugin',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'SharePoint connection ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Teams integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'One-click signing',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const TemplateManagementList = [
  {
    title: 'Custom Templates',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Field-Automation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Reusable Workflows ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Batch Processing',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Version Control',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const EnterpriseIntegrationList = [
  {
    title: 'REST API access',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Custom workflows',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Single Sign-On (SSO)  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Active Directory integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit logging',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const CompetitiveEdgeList = [
  {
    title: 'Legal compliance focus',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Aadhaar integration and authentication',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Local data centers  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: '24/7 local support  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Pay-per-use pricing',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const DigitalTransformationImpactList = [
  {
    title: '90% faster Document Processing',
    text: 'Document Processing',
    Icon: LaptopIcon
  },
  {
    title: '60% Cost Reduction',
    text: 'Cost Reduction',
    Icon: LaptopIcon
  },
  {
    title: '100% Paperless Operations',
    text: 'Paperless Operations',
    Icon: LaptopIcon
  },
  {
    title: '0 Physical Storage Needed',
    text: 'Physical Storage Needed',
    Icon: LaptopIcon
  },
  {
    title: 'Instant Verification',
    Icon: LaptopIcon
  }
];

//------------------ Workflows Routes Content starts-------------------------------------------

const WorkflowsList = [
  {
    title: 'Smart Approval Chains',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/workflows/smart-approval-chains'
  },
  {
    title: 'Automated Document Management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/workflows/automated-document-management'
  },
  {
    title: 'Role-Based Controls',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/workflows/role-based-controls'
  },
  {
    title: 'Template Library',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/workflows/template-library'
  },
  {
    title: 'Workflow Automation Features',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/workflows/workflow-automation-features'
  },
  {
    title: 'Batch Processing',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/workflows/batch-processing'
  }
];

const SmartApprovalChainsList = [
  {
    title: 'Design custom signing sequences for any document type ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Add multiple signers, reviewers, and approvers ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Set conditional paths based on specific actions ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Parallel and sequential signing options ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Real-time notifications at each stage ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AutomatedDocManagement = [
  {
    title: 'Schedule automatic reminders for pending signatures ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Track document status in real-time  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bulk upload and process multiple documents ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Detailed audit trails and activity logs  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const RoleBasedControls = [
  {
    title: 'Assign specific permissions to team members  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Define approval hierarchies  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Restrict access by department or designation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Temporary access delegation  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Custom role creation  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const TemplateLibraryList = [
  {
    title: 'Create and save reusable document templates ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Auto-populate fields from your database ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Smart field recognition and mapping ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Version history tracking  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Template sharing across teams ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const WorkflowAutomationFeaturesList = [
  {
    title: 'Drag-and-drop workflow designer ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Pre-built workflow templates ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automatic field validation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document expiry management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Integration with major business tools ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const BatchProcessingList = [
  {
    title: 'Process similar documents in batches ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bulk recipient management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automated field filling  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Mass template application ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Batch status tracking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

//--------------------------------------- Integrations--------------------------------
const IntegrationsList = [
  {
    title: 'Lexops Platform Integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/lexops-platform-integration'
  },
  {
    title: 'Enterprise-Grade Integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/enterprise-grade-integration'
  },
  {
    title: 'Seamless Microsoft Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/seamless-microsoft-integration'
  },
  {
    title: 'Additional Integrations',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/additional-integrations'
  },
  {
    title: 'Compliance Database Integrations',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/compliance-database-integration'
  },
  {
    title: 'Regulatory Database Integrations  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/regulatory-database-integrations'
  },
  {
    title: 'Additional Features ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/additional-features'
  }
];

const LexopsPlatformIntegrationList = [
  {
    title: 'Full compliance with IT Act 2000 ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Digital signatures with legal validity',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Court-admissible audit trails with detailed timestamps',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Integration with licensed Digital Signature Certificates (DSC) ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'eSign API for paperless transactions ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Complete GDPR compliance framework',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Secure data storage within Indian territory ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Real-time legal validity checks ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const EnterpriseGradeIntegration = [
  {
    title: 'Comprehensive REST API ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/enterprise-grade-integration/comprehensive-rest-api'
  },
  {
    title: 'Authentication & Security  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/enterprise-grade-integration/auth-security'
  },
  {
    title: 'Advanced Logging  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/enterprise-grade-integration/advanced-logging'
  }
];

const ComprehensiveRestApiList = [
  {
    title: 'Custom implementation support ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Secure API endpoints',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Detailed documentation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Development sandbox  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AuthenticationSecurityList = [
  {
    title: 'Single Sign-On (SSO) support',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Active Directory integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Multi-factor authentication',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Role-based access control ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AdvancedLoggingList = [
  {
    title: 'Detailed audit trails ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'User activity monitoring ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Security event logging',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Compliance reporting ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const SeamlessMicrosoftIntegrationList = [
  {
    title: 'Word Addin ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/seamless-microsoft-integration/word-addin'
  },
  {
    title: 'Sharepoint Connection ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/seamless-microsoft-integration/sharedpoint-connection'
  },
  {
    title: 'Team Integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/seamless-microsoft-integration/team-integration'
  }
];

const WordAddinList = [
  {
    title: 'Sign Documents directly in Word',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Access Templates without switching apps',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Track changes and versions',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const SharePointList = [
  {
    title: 'Direct Document Storage and retrieval',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automatic File Organisation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Version control Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const TeamIntegrationList = [
  {
    title: 'Real Time Collaboration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document sharing and Tracking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AdditionalIntergrationList = [
  {
    title: 'Aadhar Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/additional-integrations/aadhar-integration'
  },
  {
    title: 'API Protocol Support',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/additional-integrations/api-protocol-support'
  },
  {
    title: 'Contract Lifecycle Management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/integrations/additional-integrations/contract-lifecycle-management'
  }
];

const AadharIntegrationList = [
  {
    title: 'eKYC verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'OTP Validation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Instant Identity Verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const ApiProtocolSupportList = [
  {
    title: 'Open Network Compatability',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Decentralised Commerce Support',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Standardised Interfaces',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Interoperable Systems',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const CLMLIST = [
  {
    title: 'Automated Contract Creation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Lifecycle Tracking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Renewal Management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Risk assessment Tools',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ComplianceDatabaseIntegrations = [
  {
    title: 'Real time Compliance Checks',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'PAN Database Verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'MCA Database Connection',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bank Account Validation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const RegulatoryDatabaseIntegrations = [
  {
    title: 'RBI Database Access',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'SEBI Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Insurance Regulatory checks',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Legal Entity Verification',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AdditionalFeaturesList = [
  {
    title: 'Real time API access to all databses',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automated Compliance Allerts',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Regular database updates',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Custom Validation rules',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Detailed verification reports',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit Trail for all checks',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Batch verification capability',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

//-----------Contracts screen-----------------

const ContractsList = [
  {
    title: 'Smart Contract Management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/smart-contract-management'
  },
  {
    title: 'Vendor Management Tools ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/vendor-management-tools'
  },
  {
    title: 'Purchase Order Automation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/purchase-order-automation'
  },
  {
    title: 'Sales Contract Acceleration  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/sales-contract-acceleration'
  },
  {
    title: 'Compliance & Control  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/compliance-control'
  },
  {
    title: 'Analytics & Reporting  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/analytics-reporting'
  },
  {
    title: 'Collaboration Features  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/collaboration-features'
  },
  {
    title: 'Integration Capabilities  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/integration-capabilities'
  },
  {
    title: 'Physical and Digital Delivery of Contracts for Execution ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/contracts/physical-digital-delivery-of-contracts-for-execution'
  }
];

const SmartContractsManagementList = [
  {
    title: 'Streamline vendor agreements, purchase orders, and sales contracts ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automate repetitive contract creation tasks ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Pre-approved clause library  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Dynamic template generation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Multi-party contract coordination ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const VendorManagementToolsList = [
  {
    title: 'Centralized vendor documentation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Customizable approval matrices  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automated vendor onboarding workflows   ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Performance tracking integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Renewal reminders and alerts ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const PuurchaseOrderAutomation = [
  {
    title: 'Standard PO templates  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automated numbering system  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Budget approval integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Terms and conditions library ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bulk PO processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const SalesContractAcceleration = [
  {
    title: 'Quote-to-contract automation  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Standard pricing integration  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Term negotiation tracking  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Revenue impact analysis ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'CRM integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ComplianceControlList = [
  {
    title: 'Role-based contract access   ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Approval hierarchy enforcement  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automated compliance checks  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Value-based routing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Risk assessment tools ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AnalyticsReporting = [
  {
    title: 'Contract cycle time tracking   ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bottleneck identification   ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Cost savings analysis  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Vendor performance metrics  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Compliance reporting ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const CollaborationFeaturesList = [
  {
    title: 'In-document negotiations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Comment and revision tracking  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Real-time collaboration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Mobile contract review  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'In-document negotiations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const IntergrationCapabilities = [
  {
    title: 'ERP system connection ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Procurement platform linking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Payment system integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Inventory management sync ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Accounting software compatibility  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'In-document negotiations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const PhysicalDigitalDeliveryContractsForExcutionList = [
  {
    title: 'Schedule and send signed contracts, agreements, drafts through',
    text: (
      <>
        <ul>
          <li>physical channels like courier, registered post Ack /Due, and Speed Post</li>
          <li>digital channels like email, SMS, WhatsApp, etc.</li>
        </ul>
      </>
    ),
    Icon: LaptopIcon
  },
  {
    title: 'Tracking delivery status in real-time and confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Prepare comprehensive MIS reports on proof of delivery (POD) confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit trail of all physical and digital delivery for compliance and record-keeping',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automating follow-up actions and escalation procedures. ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const HumanRourcesList = [
  {
    title: 'Employee Onboarding ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/employee-onboarding'
  },
  {
    title: 'HR Forms Automation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/hr-forms-automation'
  },
  {
    title: 'Employee Lifecycle Management  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/employee-lifecycle-management'
  },
  {
    title: 'Process Efficiency  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/process-efficiency'
  },
  {
    title: 'Integration Capabilities  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/integration-capabilities'
  },
  {
    title: 'Security & Privacy  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/security-privacy'
  },
  {
    title: 'Physical and Digital Delivery  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/physical-digital-delivery'
  }
];

const EmployeeOnboardingList = [
  {
    title: 'Offer Management  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/employee-onboarding/offer-management'
  },
  {
    title: 'Documentation Bundle ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/employee-onboarding/documentation-bundle'
  }
];

const OfferManagementList = [
  {
    title: 'Automated Offer Generation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Salary Structure Integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Term Sheet Customization',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Acceptance Tracking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Revision History',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const DocumentionBundle = [
  {
    title: 'Employment contracts ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'NDA agreements ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Code of conduct ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Policy acknowledgments ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Benefits enrollment ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const HRFormsAutomationList = [
  {
    title: 'Employee Data Collection  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/hr-forms-automation/employee-data-collection'
  },
  {
    title: 'Compliance Documents ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/hr-forms-automation/compliance-documents'
  }
];

const EmployeeDataCollection = [
  {
    title: 'Personal information forms  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bank account details ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Emergency contacts ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Insurance nominations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Investment declarations  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const ComplianceDocumentsList = [
  {
    title: 'PF registration  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'ESI enrolment  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Professional tax forms ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Labor law compliances ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'State-specific forms  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const EmployeeLifecycleManagement = [
  {
    title: 'Regular HR Processes  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/employee-lifecycle-management/regular-hr-process'
  },
  {
    title: 'Exit Processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/employee-lifecycle-management/exit-processing'
  }
];

const RegularHRProcessesList = [
  {
    title: 'Performance reviews',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Promotion letters  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Transfer orders  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Policy updates ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Training acknowledgments ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ExitProcessingList = [
  {
    title: 'Resignation acceptance ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Exit interviews  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Clearance forms  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Experience letters  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Full and final settlement ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ProcessEfficiencyList = [
  {
    title: 'Smart Templates  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/process-efficiency/smart-templates'
  },
  {
    title: 'Workflow Automation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/process-efficiency/workflow-automation'
  }
];

const SmartTemplatesList = [
  {
    title: 'Role-based templates ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Auto-fill capabilities ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bulk processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document packages ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Version control  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const WorkflowAutomationList = [
  {
    title: 'Approval hierarchies ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Reminder systems ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status tracking  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document routing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Validation checks ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const IntergrationCapabilitiesList = [
  {
    title: 'HRMS Connection ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/integration-capabilities/hrms-connection'
  },
  {
    title: 'Analytics & Reporting  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/integration-capabilities/analytics-reporting'
  }
];

const HrmsConnectionList = [
  {
    title: 'Employee database sync',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Attendance systems ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Payroll software ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Performance management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Learning management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AnalyticsReportingList = [
  {
    title: 'Processing time metrics ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Completion rates ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Bottleneck analysis  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Compliance reports ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit trails ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const SecurityPrivacyList = [
  {
    title: 'Data Protection  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/human-resources/security-privacy/data-protection'
  }
];

const DataProtectionList = [
  {
    title: 'Role-based access ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Information encryption ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Privacy controls  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Secure storage ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit logging ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const PhysicalDigitalDeliveryList = [
  {
    title: 'Users have the option to schedule and send signed employment contracts, letters, notices through',
    text: (
      <>
        <ul>
          <li>physical channels like courier, registered post Ack /Due, and Speed Post</li>
          <li>digital channels like email, SMS, WhatsApp, etc.</li>
        </ul>
      </>
    ),
    Icon: LaptopIcon
  },
  {
    title: 'Tracking delivery status in real-time and confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Prepare comprehensive MIS reports on proof of delivery (POD) confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit trail of all physical and digital delivery for compliance and record-keeping',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automating follow-up actions and escalation procedures. ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const GovtRegulatoryComplianceList = [
  {
    title: 'Digital Compliance Management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/digital-compliance-management'
  },
  {
    title: 'Stamp Duty Processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/stamp-duty-processing'
  },
  {
    title: 'Government Applications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/government-applications'
  },
  {
    title: 'Security & Authentication ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/security-applications'
  },
  {
    title: 'Compliance Features ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/compliance-features'
  },
  {
    title: 'Physical and Digital Delivery ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/physical-and-digital-delivery'
  }
];

const DigitalComplianceManagementList = [
  {
    title: 'Regulatory Filings',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/digital-compliance-management/regulatory-filings'
  },
  {
    title: 'License Management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/digital-compliance-management/license-management'
  }
];

const RegulatoryFilingsList = [
  {
    title: 'Automated compliance calendars ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document validity tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Multi-level approvals ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Compliance alerts ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status monitoring ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const LicenseManagementList = [
  {
    title: 'License application workflows ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Renewal tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Supporting document collection ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Authority notifications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status updates ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const StampDutyProcessingList = [
  {
    title: 'Digital Stamping',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/stamp-duty-processing/digital-stamping'
  },
  {
    title: 'Document Registration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/stamp-duty-processing/document-registration'
  }
];

const DigitalStampingList = [
  {
    title: 'e-Stamp paper integration',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automatic duty calculation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'State-wise compliance',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Verification system',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Duty payment tracking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const DocumentRegistrationList = [
  {
    title: 'Registration workflows ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Authority submissions ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Payment processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Record maintenance ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const GovernmentApplicationsList = [
  {
    title: 'Application Processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/government-applications/application-processing'
  },
  {
    title: 'Department Integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/government-applications/department-integration'
  }
];
const ApplicationProcessingList = [
  {
    title: 'Form automation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document verification ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Department routing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Progress tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status notifications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const DepartmentIntegrationList = [
  {
    title: 'Inter-department workflows ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document sharing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Approval chains ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Record synchronization  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit maintenance',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const SecurityAuthenticationList = [
  {
    title: 'Digital Signatures',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/security-applications/digital-signatures'
  },
  {
    title: 'Document Security',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/security-applications/document-security'
  }
];

const DigitalSignaturesList = [
  {
    title: 'Form automation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document verification ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Department routing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Progress tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status notifications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const DocumentSecurityList = [
  {
    title: 'Encryption protocols',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Access controls',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Version control ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit trails',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Tamper detection',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ComplianceFeaturesList = [
  {
    title: 'Regulatory Framework',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/compliance-features/regulatory-framework'
  },
  {
    title: 'Record Keeping',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/gov-regulatory-&-compliance/compliance-features/record-keeping'
  }
];

const RegulatoryFrameworkList = [
  {
    title: 'IT Act compliance',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'GDPR readiness ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Data residency ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Industry standards ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Security protocols ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const RecordKeepingList = [
  {
    title: 'Document archival ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Retrieval systems ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Compliance reporting  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit preparation  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Evidence preservation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const PhysicalAndDigitalDeliveryList = [
  {
    title: 'Users have the option to schedule and send signed employment contracts, letters, notices through',
    text: (
      <>
        <ul>
          <li>physical channels like courier, registered post Ack /Due, and Speed Post</li>
          <li>digital channels like email, SMS, WhatsApp, etc.</li>
        </ul>
      </>
    ),
    Icon: LaptopIcon
  },
  {
    title: 'Tracking delivery status in real-time and confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Prepare comprehensive MIS reports on proof of delivery (POD) confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit trail of all physical and digital delivery for compliance and record-keeping',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automating follow-up actions and escalation procedures. ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

//-------Banking, insurance & Real Estate----------------

const BankingInsuranceAndRealEstate = [
  {
    title: 'Banking Document Processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/banking-document-processing'
  },
  {
    title: 'Insurance Documentation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/insurance-documentation'
  },
  {
    title: 'Real Estate Transactions',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/real-estate-transactions'
  },
  {
    title: 'Privacy & Security',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/privacy-security'
  },
  {
    title: 'Process Efficiency',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/process-efficiency'
  },
  {
    title: 'Physical and Digital Delivery ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/physical-and-digital-delivery'
  }
];

const BankingDocumentProcessingList = [
  {
    title: 'Loan Application Automation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/banking-document-processing/loan-application-automation'
  },
  {
    title: 'Account Management ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/banking-document-processing/account-management'
  }
];

const LoanApplicationAutomationList = [
  {
    title: 'Digital application forms ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'KYC document collection ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Credit score integration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Income verification workflow ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Auto-generated offer letters ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const AccountManagementList = [
  {
    title: 'Account opening forms ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Mandate registration ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Service requests ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Investment documentation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Card applications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const InsuranceDocumentationList = [
  {
    title: 'Policy Management  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/insurance-documentation/policy-management'
  },
  {
    title: 'Claims Processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/insurance-documentation/claims-processing'
  }
];

const PolicyManagementList = [
  {
    title: 'Digital policy issuance  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Claim submission workflow ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Policy endorsements ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Renewal automation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Beneficiary management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ClaimsProcessingList = [
  {
    title: 'Structured claim forms ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Document collection checklists ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Assessment workflows ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Settlement documentation ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status tracking ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const RealEstateTransactionsList = [
  {
    title: 'Property Documentation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/real-estate-transactions/property-documentation'
  },
  {
    title: 'Mortgage Processing',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/real-estate-transactions/mortgage-processing'
  }
];

const PropertyDocumentation = [
  {
    title: 'Rental contracts ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Title transfer documents  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Maintenance agreements ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const MortgageProcessingList = [
  {
    title: 'Loan applications ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Property valuation reports',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Legal documentation  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Disbursement letters ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'EMI schedules',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
const PrivacyAndSecurityList = [
  {
    title: 'Data Protection',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/privacy-security/data-protection'
  },
  {
    title: 'Compliance Features',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/privacy-security/compliance-features'
  }
];

const DataProtectionListBanking = [
  {
    title: 'Privacy masking',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Access controls ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit logging ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Secure storage ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ComplianceFeaturesListBanking = [
  {
    title: 'Standard regulatory guidelines adherence  ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Legal validation',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const ProcessingEfficiencyList = [
  {
    title: 'Automated Workflows ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/process-efficiency/automated-workflows'
  },
  {
    title: 'Integration Capabilities ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon,
    link: '/banking-insurance-&-real-estate/process-efficiency/integration-capabilities'
  }
];

const AutomatedWorkflowsList = [
  {
    title: 'Sequential approvals',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Parallel processing ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Status notifications',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Reminder systems',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Escalation management',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const IntegrationCapabilitiesList = [
  {
    title: 'Core banking systems',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Insurance platforms ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Property portals',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Credit bureaus',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Payment gateways',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];

const PhysicalAndDigitalDeliveryListBanking = [
  {
    title: 'Users have the option to schedule and send signed employment contracts, letters, notices through',
    text: (
      <>
        <ul>
          <li>physical channels like courier, registered post Ack /Due, and Speed Post</li>
          <li>digital channels like email, SMS, WhatsApp, etc.</li>
        </ul>
      </>
    ),
    Icon: LaptopIcon
  },
  {
    title: 'Tracking delivery status in real-time and confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Prepare comprehensive MIS reports on proof of delivery (POD) confirmations ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Audit trail of all physical and digital delivery for compliance and record-keeping',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  },
  {
    title: 'Automating follow-up actions and escalation procedures. ',
    text: 'Lorem ipsum dolor sit amet consectetur. Egestas turpis sed feugiat sit eget.',
    Icon: LaptopIcon
  }
];
//------------------------Routes-----------------
export const AllSlugsListData = {
  '/features': {
    title: 'Transform Your Document Signing Process',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: AllFeaturesConstArray
  },
  '/features/why-its-for': {
    title: 'Who it’s for',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: WhyItsForFeatures
  },
  '/features/why-its-for/banking-financial-institutions': {
    title: 'Banking & Financial Institutions',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: BankingNFinancialInstitutionsList,
    isChild: true
  },
  '/features/why-its-for/govt-public-sector': {
    title: 'Government & Public Sector',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: GovermentAndPublicSectorList,
    isChild: true
  },

  '/features/why-its-for/legal-professionals': {
    title: 'Legal Professionals',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: LegalProfessionalsList,
    isChild: true
  },
  '/features/why-its-for/corporate-enterprises': {
    title: 'Corporate Enterprises',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: CorporateEnterprisesList,
    isChild: true
  },
  '/features/core-features': {
    title: 'Core Features',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: CoreFeaturesList
  },
  '/features/core-features/aadhar-based-authentication': {
    title: 'Aadhar Based Authentication',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: AadharBasedAuthFeaturesList,
    isChild: true
  },
  '/features/core-features/legal-compliance-hub': {
    title: 'Legal Compliance Hub',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: LegalComplianceHubList,
    isChild: true
  },
  '/features/core-features/smart-workflow-tools': {
    title: 'Smart Workflow Tools',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: SmartWorkflowToolsList,
    isChild: true
  },
  '/features/core-features/microsoft-integration': {
    title: 'Microsoft Integration ',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: MicrosoftIntegrationList,
    isChild: true
  },
  '/features/core-features/template-management': {
    title: 'Template Management ',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: TemplateManagementList,
    isChild: true
  },
  '/features/core-features/enterprise-integration': {
    title: 'Enterprise Integration ',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: EnterpriseIntegrationList,
    isChild: true
  },
  '/features/competitive-edge': {
    title: 'Competitive Edge ',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: CompetitiveEdgeList,
    isChild: true
  },
  '/features/digital-transformation-impact': {
    title: 'Digital Transformation Impact',
    description: 'Enterprise-Grade Digital Signatures for those who Demand Security and Compliance',
    list: DigitalTransformationImpactList,
    isChild: true
  },
  '/workflows': {
    title: 'Streamline your Document Workflows',
    description: `Transform your document signing process with DigieSign's intelligent workflow tools.`,
    list: WorkflowsList
  },
  '/workflows/smart-approval-chains': {
    title: 'Streamline your Document Workflows',
    description: `Enterprise-Grade Digital Signatures for those who Demand Security and Compliance`,
    list: SmartApprovalChainsList,
    isChild: true
  },
  '/workflows/automated-document-management': {
    title: 'Automated Document Management',
    description: `Enterprise-Grade Digital Signatures for those who Demand Security and Compliance`,
    list: AutomatedDocManagement,
    isChild: true
  },
  '/workflows/role-based-controls': {
    title: 'Role-Based Controls',
    description: `Enterprise-Grade Digital Signatures for those who Demand Security and Compliance`,
    list: RoleBasedControls,
    isChild: true
  },
  '/workflows/template-library': {
    title: 'Template Library',
    description: `Enterprise-Grade Digital Signatures for those who Demand Security and Compliance`,
    list: TemplateLibraryList,
    isChild: true
  },
  '/workflows/workflow-automation-features': {
    title: 'Workflow Automation Features',
    description: `Enterprise-Grade Digital Signatures for those who Demand Security and Compliance`,
    list: WorkflowAutomationFeaturesList,
    isChild: true
  },
  '/workflows/batch-processing': {
    title: 'Batch Processing',
    description: `Enterprise-Grade Digital Signatures for those who Demand Security and Compliance`,
    list: BatchProcessingList,
    isChild: true
  },
  '/workflows/why-onenda-matters-for-you': {
    title: 'Why oneNDA Matters for You',
    description: `Enterprise-Grade Digital Signatures for those who Demand Security and Compliance`,
    list: BatchProcessingList,
    isChild: true,
    onClick: (navigate) => {
      window.location.href = '/why-onenda-matters-for-you';
    }
  },
  '/integrations': {
    title: 'Powerful Integrations for Seamless Workflows ',
    description: `Over 70+ integration with legal, regulatory & compliance tools`,
    list: IntegrationsList
  },
  '/integrations/lexops-platform-integration': {
    title: 'Lexops Platform Integration  ',
    description: ``,
    list: LexopsPlatformIntegrationList,
    isChild: true
  },
  '/integrations/enterprise-grade-integration': {
    title: 'Enterprise-Grade Integration ',
    description: ``,
    list: EnterpriseGradeIntegration
  },
  '/integrations/enterprise-grade-integration/comprehensive-rest-api': {
    title: 'Comprehensive REST API ',
    description: ``,
    list: ComprehensiveRestApiList,
    isChild: true
  },
  '/integrations/enterprise-grade-integration/auth-security': {
    title: 'Authentication & Security ',
    description: `Over 70+ integration with legal, regulatory & compliance tools`,
    list: AuthenticationSecurityList,
    isChild: true
  },
  '/integrations/enterprise-grade-integration/advanced-logging': {
    title: 'Advanced Logging',
    description: ``,
    list: AdvancedLoggingList,
    isChild: true
  },
  '/integrations/seamless-microsoft-integration': {
    title: 'Seamless Microsoft Integration',
    description: ``,
    list: SeamlessMicrosoftIntegrationList
  },
  '/integrations/seamless-microsoft-integration/word-addin': {
    title: 'Word Addin',
    description: ``,
    list: WordAddinList,
    isChild: true
  },
  '/integrations/seamless-microsoft-integration/sharedpoint-connection': {
    title: 'Sharepoint Connection',
    description: ``,
    list: SharePointList,
    isChild: true
  },
  '/integrations/seamless-microsoft-integration/team-integration': {
    title: 'Teams Integration',
    description: ``,
    list: TeamIntegrationList,
    isChild: true
  },

  '/integrations/additional-integrations': {
    title: 'Additional Integration',
    description: ``,
    list: AdditionalIntergrationList
  },
  '/integrations/additional-integrations/aadhar-integration': {
    title: 'Aadhar Integration',
    description: ``,
    list: AadharIntegrationList,
    isChild: true
  },
  '/integrations/additional-integrations/api-protocol-support': {
    title: 'API Protocol Support',
    description: ``,
    list: ApiProtocolSupportList,
    isChild: true
  },
  '/integrations/additional-integrations/contract-lifecycle-management': {
    title: 'Contract Lifecycle Management',
    description: ``,
    list: CLMLIST,
    isChild: true
  },
  '/integrations/compliance-database-integration': {
    title: 'Compliance Database Integrations',
    description: ``,
    list: ComplianceDatabaseIntegrations,
    isChild: true
  },
  '/integrations/regulatory-database-integrations': {
    title: 'Regulatory Database Integrations',
    description: ``,
    list: RegulatoryDatabaseIntegrations,
    isChild: true
  },
  '/integrations/additional-features': {
    title: 'Additional Features',
    description: ``,
    list: AdditionalFeaturesList,
    isChild: true
  },
  '/contracts': {
    title: 'Contracts',
    description: `Accelerate sales contracts, purchase orders, and vendor agreements with vendors & suppliers.`,
    list: ContractsList
  },
  '/contracts/smart-contract-management': {
    title: 'Smart Contract Management',
    description: ``,
    list: SmartContractsManagementList,
    isChild: true
  },
  '/contracts/vendor-management-tools': {
    title: 'Vendor Management Tools',
    description: ``,
    list: VendorManagementToolsList,
    isChild: true
  },
  '/contracts/purchase-order-automation': {
    title: 'Purchase Order Automation',
    description: ``,
    list: PuurchaseOrderAutomation,
    isChild: true
  },
  '/contracts/sales-contract-acceleration': {
    title: 'Sales Contract Acceleration ',
    description: ``,
    list: SalesContractAcceleration,
    isChild: true
  },
  '/contracts/compliance-control': {
    title: 'Compliance & Control ',
    description: ``,
    list: ComplianceControlList,
    isChild: true
  },
  '/contracts/analytics-reporting': {
    title: 'Analytics & Reporting ',
    description: ``,
    list: AnalyticsReporting,
    isChild: true
  },
  '/contracts/collaboration-features': {
    title: 'Collaboration Features  ',
    description: ``,
    list: CollaborationFeaturesList,
    isChild: true
  },
  '/contracts/integration-capabilities': {
    title: 'Integration Capabilities ',
    description: ``,
    list: IntergrationCapabilities,
    isChild: true
  },
  '/contracts/physical-digital-delivery-of-contracts-for-execution': {
    title: 'Physical and Digital Delivery of Contracts for Execution  ',
    description: ``,
    list: PhysicalDigitalDeliveryContractsForExcutionList,
    isChild: true
  },
  '/human-resources': {
    title: 'Human Resources',
    description: `Simplify employee offer letters, contracts and HR onboarding forms.`,
    list: HumanRourcesList
  },
  '/human-resources/employee-onboarding': {
    title: 'Employee Onboarding ',
    description: ``,
    list: EmployeeOnboardingList
  },
  '/human-resources/employee-onboarding/offer-management': {
    title: 'Offer Management',
    description: ``,
    list: OfferManagementList,
    isChild: true
  },
  '/human-resources/employee-onboarding/documentation-bundle': {
    title: 'Documentation Bundle  ',
    description: ``,
    list: DocumentionBundle,
    isChild: true
  },
  '/human-resources/hr-forms-automation': {
    title: 'HR Forms Automation',
    description: ``,
    list: HRFormsAutomationList
  },
  '/human-resources/hr-forms-automation/employee-data-collection': {
    title: 'Employee Data Collection ',
    description: ``,
    list: EmployeeDataCollection,
    isChild: true
  },
  '/human-resources/hr-forms-automation/compliance-documents': {
    title: 'Compliance Documents ',
    description: ``,
    list: ComplianceDocumentsList,
    isChild: true
  },
  '/human-resources/employee-lifecycle-management': {
    title: 'Employee Lifecycle Management ',
    description: ``,
    list: EmployeeLifecycleManagement
  },
  '/human-resources/employee-lifecycle-management/regular-hr-process': {
    title: 'Regular HR Processes ',
    description: ``,
    list: RegularHRProcessesList,
    isChild: true
  },
  '/human-resources/employee-lifecycle-management/exit-processing': {
    title: 'Exit Processing ',
    description: ``,
    list: ExitProcessingList,
    isChild: true
  },
  '/human-resources/process-efficiency': {
    title: 'Process Efficiency ',
    description: ``,
    list: ProcessEfficiencyList
  },
  '/human-resources/process-efficiency/smart-templates': {
    title: 'Smart Templates ',
    description: ``,
    list: SmartTemplatesList,
    isChild: true
  },
  '/human-resources/process-efficiency/workflow-automation': {
    title: 'Workflow Automation ',
    description: ``,
    list: WorkflowAutomationList,
    isChild: true
  },

  '/human-resources/integration-capabilities': {
    title: 'Integration Capabilities ',
    description: ``,
    list: IntergrationCapabilitiesList
  },
  '/human-resources/integration-capabilities/hrms-connection': {
    title: 'HRMS Connection ',
    description: ``,
    list: HrmsConnectionList,
    isChild: true
  },
  '/human-resources/integration-capabilities/analytics-reporting': {
    title: 'Analytics & Reporting  ',
    description: ``,
    list: AnalyticsReportingList,
    isChild: true
  },

  '/human-resources/security-privacy': {
    title: 'Security & Privacy  ',
    description: ``,
    list: SecurityPrivacyList
  },
  '/human-resources/security-privacy/data-protection': {
    title: 'Data Protection  ',
    description: ``,
    list: DataProtectionList,
    isChild: true
  },
  '/human-resources/physical-digital-delivery': {
    title: 'Physical and Digital Delivery  ',
    description: ``,
    list: PhysicalDigitalDeliveryList,
    isChild: true
  },
  '/gov-regulatory-&-compliance': {
    title: 'Government, Regulatory & Compliance',
    description: `Simplify and secure regulatory compliance & stamp; application processing with secure digital signatures. `,
    list: GovtRegulatoryComplianceList
  },
  '/gov-regulatory-&-compliance/digital-compliance-management': {
    title: 'Digital Compliance Management ',
    description: ``,
    list: DigitalComplianceManagementList
  },
  '/gov-regulatory-&-compliance/digital-compliance-management/regulatory-filings': {
    title: 'Regulatory Filings',
    description: ``,
    list: RegulatoryFilingsList,
    isChild: true
  },
  '/gov-regulatory-&-compliance/digital-compliance-management/license-management': {
    title: 'License Management ',
    description: ``,
    list: LicenseManagementList,
    isChild: true
  },

  '/gov-regulatory-&-compliance/stamp-duty-processing': {
    title: 'Stamp Duty Processing',
    description: ``,
    list: StampDutyProcessingList
  },
  '/gov-regulatory-&-compliance/stamp-duty-processing/digital-stamping': {
    title: 'Digital Stamping',
    description: ``,
    list: DigitalStampingList,
    isChild: true
  },
  '/gov-regulatory-&-compliance/stamp-duty-processing/document-registration': {
    title: 'Document Registration ',
    description: ``,
    list: DocumentRegistrationList,
    isChild: true
  },

  '/gov-regulatory-&-compliance/government-applications': {
    title: 'Government Applications ',
    description: ``,
    list: GovernmentApplicationsList
  },
  '/gov-regulatory-&-compliance/government-applications/application-processing': {
    title: 'Application Processing',
    description: ``,
    list: ApplicationProcessingList,
    isChild: true
  },
  '/gov-regulatory-&-compliance/government-applications/department-integration': {
    title: 'Department Integration',
    description: ``,
    list: DepartmentIntegrationList,
    isChild: true
  },

  '/gov-regulatory-&-compliance/security-applications': {
    title: 'Security & Authentication ',
    description: ``,
    list: SecurityAuthenticationList
  },
  '/gov-regulatory-&-compliance/security-applications/digital-signatures': {
    title: 'Digital Signatures',
    description: ``,
    list: DigitalSignaturesList,
    isChild: true
  },
  '/gov-regulatory-&-compliance/security-applications/document-security': {
    title: 'Document Security',
    description: ``,
    list: DocumentSecurityList,
    isChild: true
  },

  '/gov-regulatory-&-compliance/compliance-features': {
    title: 'Compliance Features ',
    description: ``,
    list: ComplianceFeaturesList
  },
  '/gov-regulatory-&-compliance/compliance-features/regulatory-framework': {
    title: 'Regulatory Framework',
    description: ``,
    list: RegulatoryFrameworkList,
    isChild: true
  },
  '/gov-regulatory-&-compliance/compliance-features/record-keeping': {
    title: 'Record Keeping',
    description: ``,
    list: RecordKeepingList,
    isChild: true
  },

  '/gov-regulatory-&-compliance/physical-and-digital-delivery': {
    title: 'Physical and Digital Delivery ',
    description: ``,
    list: PhysicalAndDigitalDeliveryList,
    isChild: true
  },
  '/banking-insurance-&-real-estate': {
    title: 'Banking, insurance & Real Estate',
    description: ``,
    list: BankingInsuranceAndRealEstate
  },
  '/banking-insurance-&-real-estate/banking-document-processing': {
    title: 'Banking Document Processing',
    description: ``,
    list: BankingDocumentProcessingList
  },
  '/banking-insurance-&-real-estate/banking-document-processing/loan-application-automation': {
    title: 'Loan Application Automation',
    description: ``,
    list: LoanApplicationAutomationList,
    isChild: true
  },
  '/banking-insurance-&-real-estate/banking-document-processing/account-management': {
    title: 'Account Management ',
    description: ``,
    list: AccountManagementList,
    isChild: true
  },
  '/banking-insurance-&-real-estate/insurance-documentation': {
    title: 'Insurance Documentation',
    description: ``,
    list: InsuranceDocumentationList
  },
  '/banking-insurance-&-real-estate/insurance-documentation/policy-management': {
    title: 'Policy Management ',
    description: ``,
    list: PolicyManagementList,
    isChild: true
  },
  '/banking-insurance-&-real-estate/insurance-documentation/claims-processing': {
    title: 'Claims Processing ',
    description: ``,
    list: ClaimsProcessingList,
    isChild: true
  },

  '/banking-insurance-&-real-estate/real-estate-transactions': {
    title: 'Real Estate Transactions',
    description: ``,
    list: RealEstateTransactionsList
  },
  '/banking-insurance-&-real-estate/real-estate-transactions/property-documentation': {
    title: 'Property Documentation',
    description: ``,
    list: PropertyDocumentation,
    isChild: true
  },
  '/banking-insurance-&-real-estate/real-estate-transactions/mortgage-processing': {
    title: 'Mortgage Processing ',
    description: ``,
    list: MortgageProcessingList,
    isChild: true
  },

  '/banking-insurance-&-real-estate/privacy-security': {
    title: 'Privacy & Security',
    description: ``,
    list: PrivacyAndSecurityList
  },
  '/banking-insurance-&-real-estate/privacy-security/data-protection': {
    title: 'Data Protection',
    description: ``,
    list: DataProtectionListBanking,
    isChild: true
  },
  '/banking-insurance-&-real-estate/privacy-security/compliance-features': {
    title: 'Compliance Features',
    description: ``,
    list: ComplianceFeaturesListBanking,
    isChild: true
  },

  '/banking-insurance-&-real-estate/process-efficiency': {
    title: 'Process Efficiency',
    description: ``,
    list: ProcessingEfficiencyList
  },
  '/banking-insurance-&-real-estate/process-efficiency/automated-workflows': {
    title: 'Automated Workflows',
    description: ``,
    list: AutomatedWorkflowsList,
    isChild: true
  },
  '/banking-insurance-&-real-estate/process-efficiency/integration-capabilities': {
    title: 'Integration Capabilities ',
    description: ``,
    list: IntegrationCapabilitiesList,
    isChild: true
  },

  '/banking-insurance-&-real-estate/physical-and-digital-delivery': {
    title: 'Physical and Digital Delivery  ',
    description: ``,
    list: PhysicalAndDigitalDeliveryListBanking,
    isChild: true
  },
  ...AllExploreSlugList
};
