'use client';

import { Button, Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import EsignAppPrimaryButton from '../Buttons/EsignAppPrimaryButton';
import EsignAppSecondaryButton from '../Buttons/EsignAppSecondaryButton';
import { CreateYouSignatureTabs } from '../Tabs/CreateYouSignatureTabs';
import { useAccount } from 'context/AccountProvider';
import { useDispatch } from 'react-redux';
import { setEsignMainAppLoader } from 'store/esignMainSlice';
import { updateUserPreferences, uploadUserSignature } from 'services/eSignService';
import { toast } from 'react-toastify';
import CheckBoxWithTitle from '../Checkboxes/CheckBoxWithTitle';

export function CreateYourSignatureModal({ afterUploadCb, onClose, showModal }) {
  const { userDetails } = useAccount();
  const [finalSignImage, setFinalSignImage] = useState(null);
  const drawSignCanvas = useRef(null);
  const [activeSignTab, setActiveSignTab] = useState(0);
  const dispatch = useDispatch();
  const [consentAccepted, setConsentAccepted] = useState(false);

  const increaseResolution = (scaleFactor) => {
    const canvas = drawSignCanvas?.current?.getTrimmedCanvas();

    if (canvas) {
      // Save the original dimensions
      const originalWidth = canvas.width;
      const originalHeight = canvas.height;

      // Create an offscreen canvas to hold the higher resolution image
      const scaledCanvas = document.createElement('canvas');
      scaledCanvas.width = originalWidth * scaleFactor;
      scaledCanvas.height = originalHeight * scaleFactor;

      // Draw the original canvas onto the scaled canvas
      const ctx = scaledCanvas.getContext('2d');
      ctx.scale(scaleFactor, scaleFactor);
      ctx.drawImage(canvas, 0, 0);

      // Convert scaled canvas to image data URL
      const imgData = scaledCanvas.toDataURL('image/svg+xml');

      return imgData;
    }

    return null;
  };
  const handleUploadToApi = async () => {
    dispatch(setEsignMainAppLoader(true));
    try {
      let image;
      console.log(finalSignImage, activeSignTab, 'dataupdated');
      if (activeSignTab === 0 || activeSignTab === 2) {
        image = finalSignImage;
      } else if (1) {
        const drawImage = drawSignCanvas?.current?.getTrimmedCanvas?.().toDataURL?.('image/svg+xml');
        image = increaseResolution(4);
      }
      const data = await updateUserPreferences({ signature_file: activeSignTab === 2 ? finalSignImage : image });

      if (data?.message) {
        toast.success('Signatures Saved Successfully!');
        await afterUploadCb();
      }
    } catch (error) {
    } finally {
      dispatch(setEsignMainAppLoader(false));
    }
  };
  const isDrawcanvasEmpty = drawSignCanvas?.current?.isEmpty();
  console.log(finalSignImage, isDrawcanvasEmpty, activeSignTab, consentAccepted, 'finalSignImage');
  return (
    <>
      <Modal show={showModal} onClose={onClose} theme={theme}>
        <Modal.Header>Create Your Signature</Modal.Header>
        <Modal.Body>
          <div>
            <CreateYouSignatureTabs
              setFinalSignImage={setFinalSignImage}
              finalSignImage={finalSignImage}
              drawSignCanvas={drawSignCanvas}
              setActiveSignTab={setActiveSignTab}
            />
            <p className="text-[#6B7280] text-[12px] font-[400] pt-[12px] flex gap-[0px] items-start">
              <CheckBoxWithTitle
                onChange={(e) => {
                  setConsentAccepted(e.target.checked ? true : false);
                }}
              />
              By clicking Create, I agree that the signature and initials will be the electronic representation of my signature and initials
              for all purposes when I (or my agent) use them on envelopes, including legally binding contracts.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <EsignAppSecondaryButton title={'Cancel'} onClick={onClose} />
          <EsignAppPrimaryButton
            title={'Create'}
            className="w-[130px] bg-[#3B82F6] h-[40px] text-[14px] font-[600] text-[white] rounded-[6px] disabled:opacity-50"
            onClick={handleUploadToApi}
            disabled={!consentAccepted || (activeSignTab === 1 ? false : !finalSignImage)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

const theme = {
  root: {
    base: 'fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full',
    show: {
      on: 'flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80',
      off: 'hidden'
    },
    sizes: {
      sm: 'max-w-sm',
      md: 'max-w-md',
      lg: 'max-w-lg',
      xl: 'max-w-xl',
      '2xl': 'max-w-2xl',
      '3xl': 'max-w-3xl',
      '4xl': 'max-w-4xl',
      '5xl': 'max-w-5xl',
      '6xl': 'max-w-6xl',
      '7xl': 'max-w-7xl'
    },
    positions: {
      'top-left': 'items-start justify-start',
      'top-center': 'items-start justify-center',
      'top-right': 'items-start justify-end',
      'center-left': 'items-center justify-start',
      center: 'items-center justify-center',
      'center-right': 'items-center justify-end',
      'bottom-right': 'items-end justify-end',
      'bottom-center': 'items-end justify-center',
      'bottom-left': 'items-end justify-start'
    }
  },
  content: {
    base: 'relative h-full w-full p-4 md:h-auto',
    inner: 'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700'
  },
  body: {
    base: 'flex-1 overflow-auto px-[20px]',
    popup: 'pt-0'
  },
  header: {
    base: 'flex items-start justify-between p-[20px]',
    popup: 'border-b-0 p-2',
    title: 'text-[16px] font-[700] text-[#101010]',
    close: {
      base: 'ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
      icon: 'h-5 w-5'
    }
  },
  footer: {
    base: 'flex items-center p-[20px] justify-end gap-[10px]',
    popup: ''
  }
};
