import AccountSettingIcon from 'app/assets/icons/Profile_Setting/AccountSettingIcon';
import BillingCreditCardIcon from 'app/assets/icons/Profile_Setting/BillingCreditCardIcon';
import NotificationSetting from 'app/assets/icons/Profile_Setting/NotificationSetting';
import PersonalSettingIcon from 'app/assets/icons/Profile_Setting/PersonalSettingIcon';
import SecurityLockIcon from 'app/assets/icons/Profile_Setting/SecurityLockIcon';
import classNames from 'classnames';
import { Sidebar } from 'flowbite-react';
import { HiArrowSmRight, HiChartPie, HiInbox, HiShoppingBag, HiTable, HiUser, HiViewBoards } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router';
const MenusConst = [
  {
    title: 'Account Settings',
    Icon: AccountSettingIcon,
    link: '/dashboard/account-setting'
  },
  {
    title: 'Notification Settings',
    Icon: NotificationSetting,
    link: '/dashboard/notification-setting'
  },
  {
    title: 'Security',
    Icon: SecurityLockIcon
  },
  {
    title: 'Billing',
    Icon: BillingCreditCardIcon,
    link: '/dashboard/billing'
  },
  {
    title: 'Personal Settings',
    Icon: PersonalSettingIcon,
    link: '/dashboard/personal-setting'
  }
];
export function ProfileSideBar({ children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  console.log(history, 'history');
  return (
    <div className="flex items-start w-full h-full">
      <Sidebar aria-label="Sidebar with logo branding example" theme={theme}>
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {MenusConst.map((item) => {
              const isActive = pathname.includes(item.link);
              return (
                <>
                  <div
                    className={classNames('flex items-center hover:bg-[#F0F9FF] hover:text-[#3B82F6] px-[10px] profile_setting_menu', {
                      'activeMenu text-[#3B82F6]': isActive
                    })}
                    onClick={() => {
                      navigate(item.link);
                    }}
                  >
                    <item.Icon color="#000000" />
                    <Sidebar.Item href="#" className="text-[#101010]">
                      {item.title}
                    </Sidebar.Item>
                  </div>
                </>
              );
            })}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
      <div className="w-full bg-[white] h-[400px] col-span-8 mt-[24px] px-[35px]">{children}</div>
    </div>
  );
}

const theme = {
  root: {
    base: 'h-full',
    collapsed: {
      on: 'w-16',
      off: 'w-70'
    },
    inner: 'h-full overflow-y-auto overflow-x-hidden rounded bg-[white] p-[20px] dark:bg-gray-800 border-r'
  },
  collapse: {
    button:
      'group flex w-full items-center rounded-lg p-2 text-base font-normal text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700',
    icon: {
      base: 'h-6 w-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white',
      open: {
        off: '',
        on: 'text-gray-900'
      }
    },
    label: {
      base: 'ml-3 flex-1 whitespace-nowrap text-left',
      icon: {
        base: 'h-6 w-6 transition delay-0 ease-in-out',
        open: {
          on: 'rotate-180',
          off: ''
        }
      }
    },
    list: 'space-y-2 py-2'
  },
  cta: {
    base: 'mt-6 rounded-lg bg-gray-100 p-4 dark:bg-gray-700',
    color: {
      blue: 'bg-cyan-50 dark:bg-cyan-900',
      dark: 'bg-dark-50 dark:bg-dark-900',
      failure: 'bg-red-50 dark:bg-red-900',
      gray: 'bg-alternative-50 dark:bg-alternative-900',
      green: 'bg-green-50 dark:bg-green-900',
      light: 'bg-light-50 dark:bg-light-900',
      red: 'bg-red-50 dark:bg-red-900',
      purple: 'bg-purple-50 dark:bg-purple-900',
      success: 'bg-green-50 dark:bg-green-900',
      yellow: 'bg-yellow-50 dark:bg-yellow-900',
      warning: 'bg-yellow-50 dark:bg-yellow-900'
    }
  },
  item: {
    base: 'flex items-center h-[44px] justify-center rounded-lg text-[16px] font-[500]  dark:text-white  ',
    active: 'bg-gray-100 dark:bg-gray-700',
    collapsed: {
      insideCollapse: 'group w-full pl-8 transition duration-75',
      noIcon: 'font-bold'
    },
    content: {
      base: 'flex-1 whitespace-nowrap px-3'
    },
    icon: {
      base: 'h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white',
      active: 'text-gray-700 dark:text-gray-100'
    },
    label: '',
    listItem: ''
  },
  items: {
    base: ''
  },
  itemGroup: {
    base: 'space-y-2 border-t border-gray-200  first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700'
  },
  logo: {
    base: 'mb-5 flex items-center pl-2.5',
    collapsed: {
      on: 'hidden',
      off: 'self-center whitespace-nowrap text-xl font-semibold dark:text-white'
    },
    img: 'mr-3 h-6 sm:h-7'
  }
};
