import LogoIcon from 'app/assets/icons/LogoIcon';
import HomePageSearchIcon from 'app/assets/icons/public_home/HomePageSearchIcon';
import MenuDropdownIcon from 'app/assets/icons/public_home/MenuDropdownIcon';
import HamBurgerMenuIcon from 'app/assets/icons/public_home/HamBurgerMenuIcon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CrossIcon from 'app/assets/icons/CrossIcon';
import UserMenuDropdownHeader from 'layout/MainLayoutNewUI/Dropdown';
import { useAccount } from 'context/AccountProvider';
import useCheckIsAuthenticated from 'app/hooks/useCheckIsAuthenticated';
import { UserHeaderAvatarMenu } from 'layout/MainLayoutNewUI/Dropdown/UserHeaderAvatarMenu';
const Menu = [
  { title: 'Features', isChild: false, link: '/features' },
  {
    title: 'Resources',
    isChild: true,
    child: [
      { title: 'Clause Library', link: 'https://dealbase.lexops.co/' },
      { title: 'Contract Library', link: 'https://dealbase.lexops.co/' },
      { title: 'Case Studies', link: '/case-studies' },
      { title: 'Whitepapers', link: '/whitepapers' },
      { title: 'Blogs', link: '/blogs' }
    ]
  },
  {
    title: 'Support',
    isChild: true,
    child: [
      { title: 'Tech Support', link: '/contact-us' },
      { title: 'Sales Desk', link: '/contact-us' },
      { title: 'FAQs', link: '/faqs' }
    ]
  },
  { title: 'Templates', isChild: false, link: '/templates' },
  { title: 'Workflows', isChild: false, link: '/workflows' },
  { title: 'Integrations', isChild: false, link: '/integrations' },
  { title: 'Pricing', isChild: false, link: '/pricing' }
  // { title: 'Contact Us', link: '/contact-us' },
  // { title: 'About Us', isChild: false, link: '/about-us' }
];
const PublicHeader = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { userDetails } = useAccount();
  const { getMyUserDetailsHandler } = useCheckIsAuthenticated();
  const token = localStorage.getItem('token');
  const [childMenuList, setChildMenuList] = useState({ isActive: false, list: [], currentPage: '' });
  useEffect(() => {
    if (token) {
      getMyUserDetailsHandler();
    }
  }, [token]);

  console.log(token, userDetails, childMenuList, 'tokenData');
  return (
    <div className="flex w-full h-[68px] items-center justify-between bg-[#FFFFFF] border-[1px] border-[#E5E7EB] px-[7%]  headerSection">
      <div className="items-center  hidden sm:flex">
        <button
          onClick={() => {
            navigate('/');
          }}
        >
          <LogoIcon width={150} height={27} />
        </button>
        <ul className="flex gap-[20px] ml-[20px]">
          {Menu.map((item) => {
            return (
              <li
                key={item}
                className="flex items-center gap-[10px] text-[#6B7280] text-[16px] font-[500] cursor-pointer relative "
                onClick={() => {
                  if (item?.isChild) {
                    setChildMenuList((state) => {
                      return { list: item?.child, isActive: !state.isActive, currentPage: item?.title };
                    });
                  } else {
                    navigate(item.link);
                  }
                }}
                // onMouseEnter={() => {
                //   if (item?.isChild) {
                //     setChildMenuList((state) => {
                //       return { list: item?.child, isActive: !state.isActive, currentPage: item?.title };
                //     });
                //   }
                // }}
              >
                {item?.title}
                {item?.isChild && (
                  <>
                    <span>
                      <MenuDropdownIcon />{' '}
                    </span>
                    {childMenuList?.isActive && childMenuList?.currentPage === item?.title ? (
                      <div className="w-fit min-w-[200px] h-fit bg-[white] border-[1px] py-[10px] absolute top-[32px] right-[-40px] rounded-[10px]">
                        {childMenuList?.list?.map((childItem) => {
                          return (
                            <div
                              className="px-[20px] flex items-center gap-[10px] text-[#6B7280] text-[16px] font-[500] cursor-pointer h-[35px]  hover:bg-[#3B82F6] hover:text-[white]"
                              onClick={() => {
                                if (childItem.link.includes('https')) {
                                  window.location.href = childItem.link;
                                } else {
                                  navigate(childItem?.link);
                                }
                              }}
                            >
                              {childItem?.title}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {/* Mobile menu */}
      <div className="flex sm:hidden w-full items-center justify-between">
        <button onClick={() => navigate('/')}>
          <LogoIcon width={150} height={31} />
        </button>
        <div className="relative flex gap-[16px]">
          <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            {isMobileMenuOpen ? <CrossIcon width={30} height={30} stroke="black" /> : <HamBurgerMenuIcon />}
          </button>
          {token && <UserHeaderAvatarMenu />}
          {isMobileMenuOpen && (
            <div
              className={`absolute  right-[-26px]  h-fit w-[100vw] top-[40px]   right- bg-[white] p-[20px] pt-[0px] overflow-y-scroll border-t-[1px]`}
            >
              <div className="h-full flex flex-col justify-between ">
                <div className="flex flex-col ">
                  {Menu.map((item) => {
                    return (
                      <li
                        key={item}
                        className="flex items-center gap-[10px] text-[black] text-[16px] font-[500] justify-between h-[48px]"
                        onClick={() => {
                          navigate(item.link);
                        }}
                      >
                        {item?.title}
                        {item?.isChild && (
                          <span>
                            <MenuDropdownIcon />{' '}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </div>
                <div className="flex items-center gap-[20px] py-[10px] border-t-[1px] ">
                  {!token ? (
                    <>
                      <button
                        className=" bg-[#030712] rounded-[6px] h-[40px] flex items-center justify-center text-[white] font-[500] text-[14px] px-[20px]"
                        onClick={() => {
                          navigate('/login');
                        }}
                      >
                        Login
                      </button>
                      <button
                        className="border-[1px] border-[#D1D5DB] bg-[white] rounded-[6px] h-[40px] flex items-center justify-center text-[#101010] font-[500] text-[14px] px-[20px]"
                        onClick={() => {
                          navigate('/register');
                        }}
                      >
                        Signup
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end mobile menu */}

      <div className=" items-center gap-[8px] hidden sm:flex">
        {!token ? (
          <>
            <HomePageSearchIcon />
            <button
              className="border-[1px] border-[#D1D5DB] bg-[white] rounded-[6px] h-[40px] flex items-center justify-center text-[#101010] font-[500] text-[14px] px-[20px]"
              onClick={() => {
                navigate('/register');
              }}
            >
              Signup
            </button>
            <button
              className=" bg-[#030712] rounded-[6px] h-[40px] flex items-center justify-center text-[white] font-[500] text-[14px] px-[20px]"
              onClick={() => {
                navigate('/login');
              }}
            >
              Login
            </button>
          </>
        ) : (
          <>
            {/* <UserMenuDropdownHeader /> */}
            <UserHeaderAvatarMenu />
          </>
        )}
      </div>
    </div>
  );
};

export default PublicHeader;
