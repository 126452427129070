import React, { useEffect, useState } from 'react';
import withPublicCommonHeader from '../withPublicCommonHeader';
import withBreadScumb from '../withBreadScumb';
import { createZohoSubscription, getAllPlansList } from 'services/eSignService';
import { isArray } from 'lodash';
import moment from 'moment';
import { useAccount } from 'context/AccountProvider';
import SubscriptionPage from './SubscriptionPage';
import classNames from 'classnames';
import CheckBoxWithTitle from 'app/components/Checkboxes/CheckBoxWithTitle';
import { TickIcon } from 'app/assets';

const PlansComponent = ({ setSelectedPlans }) => {
  const [planList, setPlanList] = useState([]);
  const { userDetails } = useAccount();
  const [planTenure, setPlanTenure] = useState('month');

  const getList = async () => {
    const data = await getAllPlansList({ tenure: planTenure });
    if (isArray(data?.data)) {
      setPlanList(data?.data);
    }
  };

  useEffect(() => {
    getList();
  }, [planTenure]);
  console.log(userDetails, 'userDetails');

  console.log(planList, 'newDtaaList');
  return (
    <>
      <div className="w-full flex  justify-end">
        <div className=" border-[1px] border-[404040] flex items-center h-[40px] w-max p-[6px] rounded-[10px] mt-[20px]">
          <button
            className={classNames('h-full rounded-[6px]   px-[10px] font-[500] text-[14px]', {
              'bg-[black] text-[white]': planTenure === 'month',
              'bg-transparent text-[#616161]': !planTenure === 'month'
            })}
            onClick={() => {
              setPlanTenure('month');
            }}
          >
            Monthly
          </button>
          <button
            className={classNames('h-full rounded-[6px]   px-[10px] font-[500] text-[14px]', {
              'bg-[black] text-[white]': planTenure === 'annual',
              'bg-transparent text-[#616161]': !planTenure === 'annual'
            })}
            onClick={() => {
              setPlanTenure('annual');
            }}
          >
            Annually
          </button>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 sm:grid-cols-3 gap-[50px]">
        {planList.map((item, index) => (
          <div className="w-full border-[1px] border-[#D4D4D8] rounded-[10px] p-[20px] mt-[20px]">
            <div className="border-b-[1px] border-[#AAAAAA] py-[20px]">
              <h4 className="font-[600] text-[16px] text-[#616161]">{item?.name}</h4>
              <p className="font-[400] text-[13px] text-[#404040] py-[4px]">{item?.description}</p>
              <h5 className="w-full font-[600] text-[13px] text-[#404040] mt-[15px]">Ideal for exploration</h5>
            </div>
            <div className="w-full mt-[20px]">
              <h2 className="text-[28px] font-[700]">{item?.price_brackets?.[0]?.price_formatted}</h2>
              <button
                className="border-[1px] border-[#3B82F6] rounded-[6px] py-[10px] px-[20px] w-full mt-[20px] text-[14px] text-[#3B82F6] font-[500]"
                onClick={() => setSelectedPlans(item)}
              >
                Get Started
              </button>
            </div>
            <div className="mt-[20px] w-full border-b-[1px] border-[#D4D4D8] pb-[20px]">
              {/* <h4 className="text-[#616161] text-[14px]  font-[500]">Basic includes</h4> */}
            </div>
            <div className="w-full mt-[20px] flex flex-col gap-[28px] px-[20px]">
              {Array.isArray(item?.feature_details?.features) &&
                item?.feature_details?.features?.map((item2) => {
                  return (
                    <div className="flex items-center gap-[10px]">
                      <img src={TickIcon} alt="tick" className=" " />
                      <h5 className="text-[12px] font-[500] text-[#101010]">
                        {item2?.tooltip_content} {item2?.name}
                      </h5>
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const PlansWithBreadcrumb = withBreadScumb(PlansComponent, { title: 'DigieSign Pricing' });

const PricingPage = () => {
  const [selectedPlans, setSelectedPlans] = useState(null);
  return (
    <>
      {selectedPlans && <SubscriptionPage selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} />}
      {selectedPlans === null && <PlansWithBreadcrumb setSelectedPlans={setSelectedPlans} />}
    </>
  );
};

export default withPublicCommonHeader(PricingPage);
