import CrossIcon from 'app/assets/icons/CrossIcon';
import SubscriptionImage from 'app/assets/images/payment/SubscriptionImage';
import CommonDropdown from 'app/components/Dropdowns/CommonDropdown';
import classNames from 'classnames';
import { useAccount } from 'context/AccountProvider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  createZohoSubscription,
  getSubscriptionCityList,
  getSubscriptionCountriesList,
  getSubscriptionStateList
} from 'services/eSignService';
export function getGST(value, percent = 18) {
  return (value * percent) / 100;
}
const CommonSelect = ({ label, options, onChange, onClick }) => {
  return (
    <label>
      <span className="font-[700]">{label}</span>
      <select className="border-[1px] border-[#D4D4D8] rounded-[10px] py-[10px] px-[10px] w-full mt-[4px]" onChange={onChange}>
        <option>Select {label}</option>
        {options.map((item) => {
          return (
            <option value={item?.value} onClick={() => onClick(item)}>
              {item?.name}
            </option>
          );
        })}
      </select>
    </label>
  );
};

const CustomInput = ({ placeholder, label, onChange, value }) => {
  return (
    <label>
      <span className="font-[700]">{label}</span>
      <input
        value={value}
        className="border-[1px] border-[#D4D4D8] rounded-[10px] py-[10px] px-[10px] w-full mt-[4px]"
        placeholder={placeholder}
        onChange={onChange}
      />
    </label>
  );
};
const SubscriptionPage = ({ selectedPlans, setSelectedPlans }) => {
  const { custom_fields, plan_code, price_brackets, tenure, user_type } = selectedPlans || {};
  const { userDetails } = useAccount();
  const { email, first_name, last_name, phone, salutation, organisation } = userDetails || {};
  const navigate = useNavigate();
  const [subscriptionFormValue, setSubscriptionFormValue] = useState({
    billingAddress: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
    gst: '',
    coupon_code: ''
  });
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  console.log(selectedPlans, subscriptionFormValue, countriesList, 'selectedPlans');
  if (!userDetails?.email) {
    alert('Please login first');
    window.location.href = '/login';
    return null;
  }
  const handleSubscribe = async () => {
    try {
      const todayDate = moment().format('YYYY-MM-DD');
      const { billingAddress, country, state, city, zipCode, gst, coupon_code } = subscriptionFormValue;
      const data = await createZohoSubscription({
        customer: {
          display_name: organisation?.id ? organisation?.name : `${first_name} ${last_name}`,
          salutation: salutation,
          first_name: first_name,
          last_name: last_name,
          email: email,
          company_name: organisation?.id ? organisation?.name : '',
          phone: phone,
          billing_address: {
            attention: billingAddress,
            street: '',
            city: city,
            state: state,
            country: country,
            zip: zipCode,
            fax: ''
          },
          shipping_address: {
            attention: '',
            street: '',
            city: '',
            state: '',
            country: '',
            zip: ''
          },
          payment_terms: 0,
          payment_terms_label: 'Due On Receipt',
          currency_code: 'INR',
          is_taxable: true,
          place_of_contact: '',
          gst_no: gst,
          gst_treatment: 'business_gst'
        },
        plan: {
          plan_code: plan_code,
          quantity: 1
        },
        addons: [],
        starts_at: todayDate,
        coupon_code: coupon_code,
        redirect_url: 'http://localhost:3000/dashboard/home'
      });
      if (data?.data?.hostedpage?.url) {
        window.open(data?.data?.hostedpage?.url);
      }
      console.log(data, 'newDtaa data');
    } catch (error) {}
  };

  const fetchAllCountries = async () => {
    try {
      const data = await getSubscriptionCountriesList();
      if (Array.isArray(data)) {
        const newData = data.map((item) => {
          return { name: item?.name, value: item?.name, id: item?.id };
        });

        setCountriesList(newData);
      }
    } catch (error) {}
  };

  const fetchAllStates = async (countryName) => {
    try {
      const countryId = countriesList.find((item) => item?.name === countryName)?.id;
      const data = await getSubscriptionStateList(countryId);
      if (Array.isArray(data)) {
        const newData = data.map((item) => {
          return { name: item?.name, value: item?.name, id: item?.id };
        });

        setStatesList(newData);
      }
    } catch (error) {}
  };

  const fetchAllCity = async (stateName) => {
    try {
      const stateId = statesList.find((item) => item?.name === stateName)?.id;
      const data = await getSubscriptionCityList(stateId);
      if (Array.isArray(data)) {
        const newData = data.map((item) => {
          return { name: item?.name, value: item?.name };
        });

        setCityList(newData);
      }
    } catch (error) {}
  };
  const handleFormvalue = (key, value) => {
    setSubscriptionFormValue((state) => {
      return { ...state, [key]: value };
    });
  };

  useEffect(() => {
    fetchAllCountries();
  }, []);
  useEffect(() => {
    if (subscriptionFormValue['country']) {
      fetchAllStates(subscriptionFormValue['country']);
    }
  }, [subscriptionFormValue['country']]);

  useEffect(() => {
    if (subscriptionFormValue['state']) {
      fetchAllCity(subscriptionFormValue['state']);
    }
  }, [subscriptionFormValue['state']]);

  const subTotal = price_brackets?.[0]?.price;
  const totalGst = getGST(subTotal);
  const totalAmount = subTotal + totalGst;

  console.log(countriesList, 'countriesList --2');
  return (
    <div className="w-full grid grid-cols-1 sm:grid-cols-2 mt-[20px] gap-[20px]">
      <div className="w-full shadow-md p-[20px] h-screen border-[1px] border-[#D4D4D8] rounded-[10px] overflow-auto">
        <div className="flex justify-between items-start">
          <h3 className="font-[700] text-[24px]">Subscription</h3>
          <button
            onClick={() => {
              setSelectedPlans(null);
            }}
          >
            <CrossIcon />
          </button>
        </div>
        <div className="mt-[20px]">
          <h4 className="font-[700] text-[16px] mb-[10px]">Your plan</h4>
          <div className="border-[1px] border-[#D4D4D8] w-full h-auto p-[20px] rounded-[10px] flex justify-between items-center">
            <div>
              <h4 className=" text-[16px]">
                For <span className="text-[#3B82F6]">{user_type}</span>
              </h4>
              <h4 className="text-[16px] mt-[10px]">( S3 License )</h4>
            </div>
            <div>
              <h4 className="text-[16px]">Enter No of Licenses</h4>
              <div className="border-[1px] border-[#D4D4D8] rounded-[10px] py-[10px] px-[10px] flex justify-between items-center mt-[10px]">
                <button className="font-[400] text-[24px]">-</button>
                <h4 className="font-[400] text-[16px]">1</h4>
                <button className="font-[400] text-[24px]">+</button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-[20px]">
          <h4 className="font-[700] text-[16px] mb-[10px]">Billing Cycle</h4>
          <div className="w-full grid grid-cols-2 gap-[20px]">
            <button
              className={classNames(
                'w-full py-[20px] px-[20px] rounded-[10px] text-[16px] font-[700] text-left',
                { 'border-[#3B82F6] border-[4px]': tenure === 'month' },
                { 'border-[#D4D4D8] border-[1px]': tenure === 'annual' }
              )}
            >
              Monthly
            </button>
            <button
              className={classNames(
                'w-full py-[20px] px-[20px]  rounded-[10px] text-[16px] font-[700] text-left',
                { 'border-[#3B82F6] border-[4px]': tenure === 'annual' },
                { 'border-[#3B82F6] border-[1px]': tenure === 'month' }
              )}
            >
              Yearly
            </button>
          </div>
        </div>
        <div className="w-full mt-[20px]">
          <h4 className="font-[700] text-[16px] mb-[10px]">Billing Address</h4>
          <input
            className="border-[1px] border-[#D4D4D8] rounded-[10px] py-[10px] px-[10px] w-full"
            placeholder="Billing Address *"
            onChange={(e) => {
              handleFormvalue('billingAddress', e.target.value);
            }}
            value={subscriptionFormValue['billingAddress']}
          />
          <div className="w-full grid grid-cols-2 mt-[10px] gap-[20px]">
            <CommonSelect
              label={'Country'}
              options={countriesList}
              onChange={(e) => {
                handleFormvalue('country', e.target.value);
              }}
            />
            <CommonSelect
              label={'State'}
              options={statesList}
              onChange={(e) => {
                handleFormvalue('state', e.target.value);
              }}
            />
            <CommonSelect
              label={'City'}
              options={cityList}
              onChange={(e) => {
                handleFormvalue('city', e.target.value);
              }}
            />
            <CustomInput
              label={'Zip Code'}
              placeholder={'Zip Code'}
              onChange={(e) => {
                handleFormvalue('zipCode', e.target.value);
              }}
              value={subscriptionFormValue['zipCode']}
            />
          </div>
        </div>
        <div className="w-full mt-[20px]">
          <h4 className="font-[700] text-[16px] mb-[10px]">Additional Details</h4>

          <CustomInput
            label={'GST'}
            placeholder={'GST (Optional)'}
            onChange={(e) => {
              handleFormvalue('gst', e.target.value);
            }}
            value={subscriptionFormValue['gst']}
          />
          <div className="w-full flex items-end justify-between mt-[10px] gap-[20px]">
            <CustomInput
              label={'Coupon Code'}
              placeholder={'Apply Coupon Code'}
              onChange={(e) => {
                handleFormvalue('coupon_code', e.target.value);
              }}
              value={subscriptionFormValue['coupon_code']}
            />
            <button className="rounded-[10px] px-[20px] h-[45px] bg-[#3B82F6] text-[white] font-[600] text-[16px] align-bottom">
              View Coupon
            </button>
          </div>
        </div>
        <div className="w-full mt-[20px] border-t-[1px] border-[#D4D4D8] pt-[20px]">
          <div className="w-full flex items-center justify-between">
            <h4 className="font-[700] text-[20px] mb-[10px]">Sub Total</h4>
            <h4 className="font-[700] text-[20px] mb-[10px]">
              ₹{subTotal} <span className="font-[400]"> / {tenure}</span>
            </h4>
          </div>
          <div className="w-full flex items-center justify-between mt-[10px]">
            <h4 className="font-[700] text-[20px] mb-[10px]">GST (18%)</h4>
            <h4 className="font-[700] text-[20px] mb-[10px]">₹{totalGst}</h4>
          </div>
          <div className="w-full flex items-center justify-between mt-[10px]">
            <h4 className="font-[700] text-[20px] mb-[10px]">Total</h4>
            <h4 className="font-[700] text-[20px] mb-[10px]">
              ₹{totalAmount} <span className="font-[400]"> / {tenure}</span>
            </h4>
          </div>
        </div>
        <div>
          <button
            className="rounded-[10px] px-[20px] h-[45px] bg-[#3B82F6] text-[white] font-[600] text-[16px] align-bottom mt-[20px] w-full"
            onClick={handleSubscribe}
          >
            Agree & Purchase
          </button>
        </div>
      </div>
      <div className="w-full flex items-center justify-center">
        <SubscriptionImage />
      </div>
    </div>
  );
};

export default SubscriptionPage;
